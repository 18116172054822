<template>
  <card class="mx-auto my-20">
    <template #title>Thanks!</template>
    <template>
      <div class="flex flex-col items-center">
        <p class="py-4 text-xs leading-4 text-black text-center">
          Your subscription is now active!
        </p>
        <t-button primary class="mx-10 mt-6" tag="router-link" to="/">My Dashboard</t-button>
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/Card.vue";

import TButton from "@/components/core/Button.vue";

export default {
  name: "SubSeccess",
  components: {
    Card,

    TButton,
  },
};
</script>
