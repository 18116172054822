<template>
  <card class="mx-auto my-20">
    <template #title>Error occured!</template>
    <template>
      <div class="flex flex-col items-center">
        <p class="py-4 text-xs text-black text-center">
          Something went wrong with your subscription Please try again or
          contact support
        </p>
        <a
          href="mailto:arturs@boss.com"
          class="text-xs text-green-600 hover:text-green-700"
          >hi@emailtool.io</a
        >
        <t-button primary class="mx-10 mt-6" tag="router-link" to="/subscribe">
          Try Again
        </t-button>
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/Card.vue";

import TButton from "@/components/core/Button.vue";

export default {
  name: "SubError",
  components: {
    Card,
    TButton,
  },
};
</script>
